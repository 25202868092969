import React, { useCallback, useMemo } from "react"
import { connect } from 'react-redux'
import { toggleFilter } from "../../state/filter"
import { getUser } from "../../services/auth"
import RenderHomeType from "../../components/Filter/RenderHomeType"
import { doFilter } from "../../services/filter"

const SidebarCategory = () => {
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  var categories = [
    { name: "All", URLSegment: "/explore/", linkClass: "active" },
    { name: "Style", URLSegment: "/explore/style/", linkClass: "link" },
    { name: "Home Designs", URLSegment: "/explore/home-designs/", linkClass: "link" },
    { name: "Facades", URLSegment: "/explore/facades/", linkClass: "link" },
    { name: "Finishes & fixtures", URLSegment: "/explore/finishes-fixtures/", linkClass: "link" },
    { name: "Cabinetry", URLSegment: "/explore/cabinetry/", linkClass: "link" },
    { name: "Furniture", URLSegment: "/explore/furniture/", linkClass: "link" },
  ]

  const categoryNav = useMemo(() => {
    return (
      <ul className="category-nav">
        {categories.map((item, idx) => (
          <li key={`category-${idx}`}>
            <a href={`${item.URLSegment}`} className={item.linkClass}>{item.name}</a>
          </li>
        ))}
      </ul>
    )
  })

  return (
    <>
      <div className="sidebar-category">
        {categoryNav}
      </div>
    </>
  )
}


export default SidebarCategory